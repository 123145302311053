<template>
  <button type="button" class="btn btn-outline alpha-success border-success-600 legitRipple" @click="clicked"><i class="icon-reload-alt mr-2"></i> {{title}}</button>
</template>

<script>

export default {
  name: 'CloseButton',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Close',
    },
  },
  created () {
    // this.$props.title = "Clicked!!!";
  },
  methods:{
    clicked() {
      const self = this;
      self.$emit('handle_close_action');
    },
  }
}
</script>

<style scoped>

</style>
